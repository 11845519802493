import React from 'react'
import { graphql, PageProps } from 'gatsby'
import '../../styles/base.css'
import { isGoogleDoc } from '../../build/google-docs/GoogleDoc.interface'

export const query = graphql`
  query ($id: String!) {
    doc: googleDoc(id: { eq: $id }) {
      id
      title
      contents
    }
  }
`

const Post: React.FC<PageProps<any>> = ({ data: { doc }, pageContext }) => {
  if (!isGoogleDoc(doc)) {
    console.log(doc)
    throw new Error('Invalid data returned from graphql')
  }

  return <section dangerouslySetInnerHTML={{ __html: doc.contents }} />
}

export default Post
