export interface GoogleDoc {
  id: string
  title: string
  contents: string
}

export function isGoogleDoc(doc: any): doc is GoogleDoc {
  return (
    (!!doc &&
      typeof doc.id === 'string' &&
      typeof doc.title === 'string' &&
      typeof doc.contents === 'string') ||
    false
  )
}

export function isGoogleDocList(list: any[]): list is GoogleDoc[] {
  return Array.isArray(list) && isGoogleDoc(list[0])
}
